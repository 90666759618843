/* $(function() {
	// Asynchronously Load the map API
  var script = document.createElement('script');
  script.src = "//maps.googleapis.com/maps/api/js?key=AIzaSyBWUJbm-ap_wWYjMN8EK-BQQglYeiOsdRk&callback=initializeMap&libraries=places";
  document.body.appendChild(script);
	
});

var cabinDetailsPageMap = function(){
//mapData = $.parseJSON(mapData);
 
	var mapOptions = {
		center: { lat: parseFloat(mapData.lat), lng: parseFloat(mapData.lng )},	  
		scrollwheel:false,		 		
		disableDoubleClickZoom: true,
		mapTypeId: google.maps.MapTypeId.ROADMAP,
		zoom:9,
		minZoom:8,
		maxZoom:13,
		streetViewControl:false
	};
	
  var mapDetail = new google.maps.Map(document.getElementById('cabin-map'), mapOptions);
	  var positions = new google.maps.LatLng(parseFloat(mapData.lat),parseFloat(mapData.lng));
        var contentString = '<div id="content">'+
            '<div id="siteNotice">'+ '</div>'+
            '<h1 id="firstHeading" class="firstHeading">'+mapData.unit_name+'</h1>'+
            '<div id="bodyContent">'+
            '<p></p>'+
            '</div>'+
            '</div>';

        var infowindow = new google.maps.InfoWindow({
          content: contentString
        });

        var marker = new google.maps.Marker({
          position: positions,
          map: mapDetail,
          title: mapData.unit_name
        });
        marker.addListener('click', function() {
          infowindow.open(mapDetail, marker);
        });

	
};




var map="";
var bounds = "";
var gmarkers = [];
// Display multiple markers on a map
var infoWindow , marker, i;
*/
$("#destination, #guests, #search-date-range-out").on('change', function (e) {
	//AjaxMap();

	});
	
function initializeMap() {
	if($("#cabin-map").length > 0){
	//cabinDetailsPageMap();
	}
	
}

	
	function AjaxMap(){
		
	} 
	
	
	
if($("#map").length > 0){

mapboxgl.accessToken = 'pk.eyJ1Ijoic21va2llc2NhYmluc2ZvcnlvdSIsImEiOiJjanRrNjF6NDkwMGxwNDRwNmRhY2UyMXdmIn0.kP1DNBrJewbyQbV7mjtG5g';
var map = new mapboxgl.Map({
  container: 'map',
  style: 'mapbox://styles/mapbox/streets-v11',
  center: [parseFloat(mapData.lng ), parseFloat(mapData.lat)], // starting position [lng, lat]
  zoom: 12, // starting zoom
  maxZoom: 14
});

// Add full screen trigger to the map.
map.addControl(new mapboxgl.FullscreenControl());
// Add zoom and rotation controls to the map.
map.addControl(new mapboxgl.NavigationControl());

// disable map zoom when using scroll
map.scrollZoom.disable();

map.on("load", function () {
	/* Image: An image is loaded and added to the map. */
	map.loadImage("/public/img/mapbox-marker.png", function(error, image) {
		if (error) throw error;
		map.addImage("custom-marker", image);
		/* Style layer: A style layer ties together the source and image and specifies how they are displayed on the map. */
		map.addLayer({
			id: "markers",
			type: "symbol",
			/* Source: A data source specifies the geographic coordinate where the image marker gets placed. */
			source: {
				type: "geojson",
				data: {
					type: 'FeatureCollection',
					features: [
					{
						type: 'Feature',
						properties: {},
						geometry: {
							type: "Point",
							coordinates: [parseFloat(mapData.lng ), parseFloat(mapData.lat)]
						}
					}
					]
				}
			},
			layout: {
				"icon-image": "custom-marker",
			}
		});
	});
});


}

	