// JS to Accompany the Blur Up Technique on the Hero image

// Blur Up Technique for Image Loading

$(document).ready(function($){
  
 // var $hiresSource = jQuery('.homepage-bg-hires').data("hires");

  $('.bg-cover').addClass("bg-cover-enhanced");

 

   // console.log ($hiresSource);
});
      