//Booking Module JavaScript 

var cfy={
	xhrURL:'',
	httpsURL:'',
	trippopup:'',
	tippyOpen:1,
	cb_unit_id:'',
	storage:Storages.localStorage,
	USCurrency:function(price){
		// Format the price above to USD using the locale, style, and currency.
		 USDollar = new Intl.NumberFormat('en-US', {
			style: 'decimal',  // Other options: 'currency', 'percent', etc.
			minimumFractionDigits: 2,
			maximumFractionDigits: 2
		});
		
		amount = USDollar.format(price);

		return amount;
	},
	quote:{
		arrival:'',
		departure:'',
		adults:'',
		nights:'',
		mn_nights:'',
		pets:'',
		tax_rate:'',
		damage_insurance_orig:'',
		trip_insurance_orig:'',
		quoteData:'',
		pp_amount:'',
		cp_amount:'',
		membership:'d',
		trip_insurance:'',
		booked_day:'',
		chtsticky:1,
		departureSelected: false,
		get:function(){
			$("#quote-box").html('<img id="quote-loader-img" src="'+cfy.xhrURL+'public/img/ajax-loader.gif" >');
			cfy.setCheckinCheckoutDate(cfy.quote.arrival,cfy.quote.departure);
			cfy.hideAddonBox();	
			if(cfy.quote.chtsticky == 1){				
				$('#quote-sticky').foundation('destroy');			
				cfy.quote.chtsticky=0;
			}
			if($("#pets").length > 0){
				cfy.quote.pets = $("#pets").val(); 				
			}
			if($("#numberofadults").length > 0){
				cfy.quote.adults = $("#numberofadults").val();
			}
			
			
			
			$.get(cfy.xhrURL+"booking/quote/",
				{	
					arrival:cfy.quote.arrival,
					departure:cfy.quote.departure,
					adults:cfy.quote.adults,
					nights:cfy.quote.nights,
					pets:cfy.quote.pets,
					cb_unit_id: $("#cb-unit-id").val(),
					pp_amnt:cfy.quote.pp_amount,
					trip_insurance_status:cfy.quote.trip_insurance,
					cp_amt:cfy.quote.cp_amount,
					membership:cfy.quote.membership
					
				},
				function(response){				
					if(response.status == "success")
					{
						cfy.quote.quoteData = response;
						cfy.quote.damage_insurance_orig=response.damage_insurance ;
						cfy.quote.trip_insurance_orig=response.trip_insurance ;
						cfy.calculateTotalPrice(response);
						cfy.showAddonBox();
						
						if(response.cfymember == 'ON')
						{
							cfy.quote.membership = 1;
						}
					}
					else{
						$("#quote-box").html('<div class="quote-message callout radius">'+response.message+'</div>');
						cfy.hideAddonBox();
						//$.notify(response.message, {position:'t c',className: 'error'});
					}
				},			
			'json')
			.fail( function(xhr, textStatus, errorThrown) {
				$("#quote-loader-img").remove();
				$.notify("Server is not responding. Please refresh page and try again!", {position:'t c',className: 'error'});
			});			
		},
	},
	couponDiscount: function(){
		var PromoCodes = $("#coupon-code-field").val();
		$(".loader-img").html('<img src="'+cfy.xhrURL+'public/img/ajax-loader.gif" >');
		$.get(cfy.xhrURL+"booking/quote/coupon_discount",
			{	
				PromoCode:PromoCodes				
			},
			function(response){		
				$(".loader-img").html('');
				if(response.status == "success")
				{
					//total_amount = parseFloat(response.total);
					total_amount = cfy.USCurrency(parseFloat(response.total));
					$(".total-amount-bx").html("$"+ total_amount);
					$(".discount-bx").html("-$"+Number(response.result.discount).toFixed(2));
					$(".discount-desc-bx").html(response.result.discount_desc + ":");
					$("#tax-amount").html("$"+ Number(response.result.tax).toFixed(2));	
					$("#coupon-code-field").notify(response.message, {position:'t c',className: 'success'});
					$('#coupon-code-field').val('');
					
					$('.full_deposit').val(Number(parseFloat(response.total)).toFixed(2));
					$('.full_deposit_lbl').html(total_amount);
					if($(".min_deposit_lbl").length > 0)
					{
						minDiposit = Number(response.min_deposit).toFixed(2);
						$(".min_deposit_lbl").html(minDiposit);
						$(".min_deposit_val").val(minDiposit);
					}
					
				}
				else{
					$("#coupon-code-field").notify(response.message, {position:'t c',className: 'error'});		
					
				}
			},			
		'json');	
	},
	
	rewardsMember: function(rewards){
		cfy.openModal();
		if($(rewards).is('#cfy-rewards-chk-yes')){
			st = "ON";	
		}
		else{
			st = "OFF";	
		}
		$.get(cfy.xhrURL+"booking/quote/coupon_discount",
			{	
				membership:st				
			},
			function(response){		
				cfy.closeModal();
				if(response.status == "success")
				{
					//total_amount = parseFloat(response.total);
					total_amount = cfy.USCurrency(parseFloat(response.total));
					$(".total-amount-bx").html("$"+ total_amount);
					if(parseFloat(response.result.discount) > 0)
					{
						$(".discount-bx").html("-$"+ Number(response.result.discount).toFixed(2) );
						$(".discount-desc-bx").html(response.result.discount_desc + ":");
					}
					else
					{
						$(".discount-bx, .discount-desc-bx").html("");
					}
					$("#tax-amount").html("$"+ Number(response.result.tax).toFixed(2));	
					
					$('.full_deposit').val(Number(parseFloat(response.total)).toFixed(2));
					$('.full_deposit_lbl').html(total_amount);
					if($(".min_deposit_lbl").length > 0)
					{
						minDiposit = Number(response.min_deposit).toFixed(2);
						$(".min_deposit_lbl").html(minDiposit);
						$(".min_deposit_val").val(minDiposit);
					}
					
				}
				else{
					$("#coupon-code-field").notify(response.message, {position:'t c',className: 'error'});		
					
				}
			},			
		'json');	
	},
	bookNow: function(){
		cfy.quote.get();
		$( document ).ajaxStop(function() {
			window.location.href= cfy.xhrURL+'booking/review_booking';
		});	
		
	},
	
	additionalFee: function(status){			
		cfy.openModal();
		$.post(cfy.xhrURL+"booking/quote/add_insurance_fee",
			{	
				trip_insurance_fee:status		
				
			},
			function(response){
				cfy.closeModal();
				if(response.status == "success")
				{			
					if(response.result.trip_insurance_status == 'ON')		
					{
						$("#tifee2").html('$'+ Number(response.result.trip_insurance).toFixed(2) );
						$(".tp-review-page").html('(Change)');
					}
					else{
						$("#tifee2").html('$0.00');
						$(".tp-review-page").html('(Add Insurance)');
					}
					//total_amount = parseFloat(response.total_price);
					total_amount = cfy.USCurrency(parseFloat(response.total_price));
					$('.full_deposit').val(Number(parseFloat(response.total_price)).toFixed(2));
					$('.full_deposit_lbl').html(total_amount);
					if($(".min_deposit_lbl").length > 0)
					{
						minDiposit = Number(response.min_deposit).toFixed(2);
						$(".min_deposit_lbl").html(minDiposit);
						$(".min_deposit_val").val(minDiposit);
					}				
					
					$("#quote-box").find('#ppfee2').html('$'+ Number(response.result.PPP_Amount).toFixed(2) );
					$("#tax-amount").html('$'+ Number(response.result.tax).toFixed(2));				
					$("#total-amount").html('<b>$'+ total_amount +'</b>');			
					$("#tiamount").html('$'+ Number(response.result.trip_insurance).toFixed(2));
				}
				else {
					$.notify(response.message, {position:'t c',className: 'error'});
				}
			},			
		'json')
		.fail( function(xhr, textStatus, errorThrown) {
			cfy.closeModal();  
			$.notify("Server is not responding. Please refresh page and try again!", {position:'t c',className: 'error'});
		});		
		
	},
	additional_Property_Fee:function(){
		//	alert($(".property-protection-review-page").data('fee'));
		//cfy.quote.damage_insurance_orig = $(".property-protection-review-page").data('fee');
		cfy.openModal();
		$.post(cfy.xhrURL+"booking/quote/add_property_fee",
			{				
				pp_amount:cfy.quote.pp_amount,			
				cb_unit_id: $("#cb-unit-id").val()			
			},
			function(response){
				cfy.closeModal();
				if(response.status == "success")
				{
					//total_amount = parseFloat(response.total_price);
					total_amount = cfy.USCurrency(parseFloat(response.total_price));
					$("#quote-box").find('#ppfee2').html('$'+ Number(response.result.PPP_Amount).toFixed(2));
					$("#tax-amount").html('$'+ Number(response.result.tax).toFixed(2));
					if($("#travel-insurance-chk").is(":checked")){	$("#tifee2").html('$'+ Number(response.result.trip_insurance).toFixed(2)); }
					$("#tiamount").html('$'+ Number(response.result.trip_insurance).toFixed(2));
					if(response.result.trip_insurance_status == 'ON')		{
						$("#tifee2").html('$'+ Number(response.result.trip_insurance).toFixed(2) );
						
					}
					else{
						$("#tifee2").html('$0.00');
					}
					$("#total-amount").html('<b>$'+total_amount +'</b>');
					$('.full_deposit').val(Number(parseFloat(response.total_price)).toFixed(2));
					$('.full_deposit_lbl').html(total_amount);
					if($(".min_deposit_lbl").length > 0)
					{
						minDiposit = Number(response.min_deposit).toFixed(2);
						$(".min_deposit_lbl").html(minDiposit);
						$(".min_deposit_val").val(minDiposit);
					}
					if(parseFloat(response.result.PPP_Amount) > 0  ){
						$(".property-protection-review-page").html('(Change)');
					}
					else {
						$(".property-protection-review-page").html('(Add Protection)');
					}
				}
				else {
					$.notify(response.message, {position:'t c',className: 'error'});
				}
			},		
		'json') 
		.fail( function(xhr, textStatus, errorThrown) {
			cfy.closeModal();  
			$.notify("Server is not responding. Please refresh page and try again!", {position:'t c',className: 'error'});
		});
		
		
	},
	
	GetResInfo:function(){
		
		$('#online_payment_form').ajaxForm({
			dataType: 'json',
			beforeSubmit: function(e) {
				cfy.openModal();
				$("#payment_form_step2").html('');
			},
			success: function(data) {               
				cfy.closeModal();
				if (data.status == 'success') {	
					$("#payment_form_step2").html(data.payments_form);
					
				}
				else{					
					$.notify(data.message, {position:'t c',className: 'error'});
				}
			},
			error: function(e) {
				cfy.closeModal();
				$.notify("Server is not responding, Please refresh page and try again!", {position:'t c',className: 'error'});
			}
		}).submit();
		
		
	},	
	
	payment:function(){
		
		$('#pform').ajaxForm({
			dataType: 'json',
			beforeSubmit: function(e) {
				cfy.openModal();
				
			},
			success: function(data) {               
				cfy.closeModal();
				if (data.status == 'success') {	
					
					$.notify(data.message, {position:'t c',className: 'success'});
					window.location.href= data.redirect;
				}
				else{					
					$.notify(data.message, {position:'t c',className: 'error'});
					$.each(data.errorFields, function(key, value) {
						$('#' + key).addClass('has-error');
					});
				}
			},
			error: function(e) {
				cfy.closeModal();
				$.notify("Server is not responding, Please refresh page and try again!", {position:'t c',className: 'error'});
			}
		}).submit();
		
		
	},	
	
	add_cancellation_fee:function(){
		cfy.openModal();
		$.post(cfy.xhrURL+"booking/quote/add_cancellation_fee",
			{				
				cpp_amount:cfy.quote.cp_amount,			
				cb_unit_id: $("#cb-unit-id").val()			
			},
			function(response){
				cfy.closeModal();
				if(response.status == "success")
				{
					//total_amount = parseFloat(response.total_price);
					total_amount = cfy.USCurrency(parseFloat(response.total_price));
					$("#tiamount").html('$'+ Number(response.result.trip_insurance).toFixed(2));
					if(response.result.trip_insurance_status == 'ON')		{
						$("#tifee2").html('$'+ Number(response.result.trip_insurance).toFixed(2));
						
					}
					else{
						$("#tifee2").html('$0.00');
					}
					
					$("#quote-box").find('#cppfee').html('$'+ Number(response.result.CPP_Amount).toFixed(2) );
					$("#tax-amount").html('$'+ Number(response.result.tax).toFixed(2));				
					$("#total-amount").html('<b>$'+ total_amount +'</b>');
					$('.full_deposit').val(Number(parseFloat(response.total_price)).toFixed(2));
					$('.full_deposit_lbl').html(total_amount);
					if($(".min_deposit_lbl").length > 0)
					{
						minDiposit = Number(response.min_deposit).toFixed(2);
						$(".min_deposit_lbl").html(minDiposit);
						$(".min_deposit_val").val(minDiposit);
					}
					if(parseFloat(response.result.CPP_Amount) > 0  ){
						$(".cancellation-protection-review-page").html('(Change)');
					}
					else {
						$(".cancellation-protection-review-page").html('(Add Protection)');
					}
				}
				else {
					$.notify(response.message, {position:'t c',className: 'error'});
				}
			},			
		'json')
		.fail( function(xhr, textStatus, errorThrown) {
			cfy.closeModal();  
			$.notify("Server is not responding. Please refresh page and try again!", {position:'t c',className: 'error'});
		});
		
		
	},
	
	openModal:function(){
		//$('#modal').show();
		//$('#fade').show();
		$('.review-ajax-loader').show();
	},
	closeModal:function(){
		//	$('#modal').hide();
		//$('#fade').hide();
		$('.review-ajax-loader').hide();
	},
	calculateTotalPrice:function(response){
		html2 =  '';
		html3 =  '';
		combineCleanHotFee = '0.00';
		taxLable = "Tax";
		
		html = '<div class="small-12 columns callout">';
		if(parseFloat(response.STD_rent) > 0)
		{
			html +=  '<div class="small-7 columns">Original Rent:</div><div class="small-5 columns text-right"><span id="rent"><del> $'+Number(response.STD_rent).toFixed(2) +'</del></span></div>';
		}		
		
		if(parseFloat(response.discount_STD_amount) > 0){
			html +=  '<div class="small-8 columns discount-red">'+response.discount_STD_per+':</div> <div class="small-4 columns text-right special-discount discount-red"><span data-fee="'+response.discount+'" id="discount-std-amnt">-$'+Number(response.discount_STD_amount).toFixed(2) +'</span> </div>';			
		}
		
		html +=  '<div class="small-7 columns">Your Rent:</div><div class="small-5 columns text-right"><span id="rent"> $'+Number(response.rent).toFixed(2) +'</span></div>';
		
		if(parseFloat(response.discount) > 0){
			html +=  '<div class="small-8 columns special-discount discount-red">'+response.discount_desc+':</div> <div class="small-4 columns text-right special-discount discount-red"><span data-fee="'+response.discount+'" id="discount-amnt">-$'+Number(response.discount).toFixed(2) +'</span> </div>';		
		}				
		
		for (var key in  response.other_charges_detail) 
		{
			if(key == 'Service Fee') 
			{ 
				taxLable = "Taxes and Service Fee" ; 
				response.tax = parseFloat(response.tax) + parseFloat(response.other_charges_detail[key]);
				response.tax = Number(response.tax).toFixed(2);
			} 
			if(key != 'Pet Fee' && key != 'Service Fee')
			{			
				if(key == 'Cleaning Fee' || key == 'Hot Tub Fee')
				{
					combineCleanHotFee = parseFloat(response.other_charges_detail[key]) + parseFloat(combineCleanHotFee);
					
					html3 +=  '<div class="small-8 columns accord-hot"><small>'+key.replace("Cleaning Fee", "Departure Clean")+':</small></div><div class="small-4 columns text-right"> <span><small>$'+Number(response.other_charges_detail[key]).toFixed(2)  +'</small></span></div>';
					
				}				
				else 
				{
					html2 +=  '<div class="small-8 columns">'+key+':</div><div class="small-4 columns text-right"> <span>$'+Number(response.other_charges_detail[key]).toFixed(2)  +'</span></div>';
				}
				
			}
		}
		
		if(response.cb_ota_cabin =='0' && response.unit_id != '459817'){
			html2 +=  '<div class="small-8 columns prop-prot">Property Protection: <span><i id="property-protection-chk"  data-fee="" class="ppp-popup fa fa-question-circle" aria-hidden="true"></i></span></div> <div class="small-4 columns text-right"><span data-fee="'+response.PPP_Amount+'" id="ppfee">$'+Number(response.PPP_Amount).toFixed(2) +'</span> </div>';
		}
		cfy.quote.pp_amount= response.PPP_Amount;
		
		//html2 +=  '<div class="small-8 columns other_fees_total">Hospitality Fees: <span class="tooltipdiv"><i class="additional-fees-tips fa fa-question-circle" aria-hidden="true"></i></span></div><div class="small-4 columns text-right other_fees_total"> <span id="rfee">$'+Number(hospitality_fee).toFixed(2)+'</span> </div>';
		////	html2 +=other_fees;
		//html2 +=other_fees_extra;
		
		if(parseFloat(response.pet_fee) > 0){	
			html2 +=  '<div class="small-8 columns">Pet Fee: ('+response.pets+')</div><div class="small-4 columns text-right"> <span data-fee="'+response.pet_fee+'" id="petfee">$'+Number(response.pet_fee).toFixed(2) +'</span> </div>';
			//quoteTotal += parseFloat(response.pet_fee);
			//fees_sum += parseFloat(response.pet_fee);
		}
		
		$('#trip-amount').html('($'+Number(response.trip_insurance).toFixed(2)+')');
		if($("#travel-insurance-chk").is(":checked")){
			var damage_insurance_original = parseFloat(response.damage_insurance);
			if(	$("#property-protection-chk").is(":checked")){
				//	damage_insurance_original = parseFloat(response.damage_insurance_original);
			}			
			html2 +=  '<div class="small-8 columns trip-ins">Trip Insurance:</div><div class="small-4 columns text-right"><span data-fee="'+response.trip_insurance+'" id="tinsurance"> $'+Number(response.trip_insurance).toFixed(2) +'</span></div>'; 			
		}
		$('#cpp-show').html(Number(response.CPP_show).toFixed(2));
		$('#cpfee1').val(Number(response.CPP_show).toFixed(2));
		$('#cpp-dynamic-amount').html("($" + Number(response.CPP_show).toFixed(2) + ")" );
		cfy.quote.cp_amount= response.CPP_Amount;
		cancellation_amount = response.CPP_Amount;
		if(response.cb_ota_cabin =='0' && cancellation_amount > parseFloat(0.00)){
			html2 +=  '<div class="small-8 columns prop-prot">Cancellation Protection:</div> <div class="small-4 columns text-right"><span data-fee="'+response.CPP_Amount+'" id="cpfee">$'+Number(response.CPP_Amount).toFixed(2) +'</span> </div>';
		}
		
		html += '<div class="small-8 columns">Cleaning Services: <i id="show-toggle-fee" class="fa fa-question-circle" aria-hidden="true"></i></div><div class="small-4 columns text-right"><span> $'+ Number(combineCleanHotFee).toFixed(2) +'</span></small></div>';
		html += '<div class="row collapse" id="toggle-fee"><div class="small-12 columns"  >' +html3 + "</div></div>";
		html += '<div class="row collapse"><div class="small-12 columns all-fees" >' +html2 + "</div></div>";
		
		
		html +=  '<div class="small-8 columns">'+taxLable+':</div><div class="small-4 columns text-right"><span data-fee="'+response.tax+'" id="tax"> $'+ Number(response.tax).toFixed(2) +'</span></small></div>';		
		
		quoteTotal = cfy.USCurrency(response.TotalPrice);
		html +=  '<div class="small-7 columns"><b>Total:</b></div><div class="small-5 columns text-right"><b> <span id="quote-total">$'+ quoteTotal +'</span></b> </div>';
		
		if(response.security_deposit != '')
		{
			html += '<div class="small-8 columns dd-deposit-desc tooltipdiv">'+response.security_deposit_description+': <i style="border-bottom: none; color: #ffd800;" class="tooltip-damage-link fa fa-question-circle has-tip" aria-hidden="true" data-tooltip aria-haspopup="true"  title="This property requires a $'+ response.security_deposit + ' hold on your card. You will not be charged unless damages are found."></i></div><div class="small-4 columns text-right dd-deposit-amnt"><span> $'+ response.security_deposit +'</span></small></div>';	
		}
		
		html +=  '</div>';		
		$("#quote-box").html(html);
		
		$("#toggle-fee").hide();
		$(document).foundation();
		
		
	},
	
	propertyProtectionFee:function(propertyProtectionFeeChk){
		if($(propertyProtectionFeeChk).hasClass('property-protection-review-page')){
			//cfy.additional_Property_Fee();	
		}
		
	},	
	tripInsuranceFee: function(InsuranceChk){
		if($(InsuranceChk).hasClass('travel-insurance-review-page')){
			$.magnificPopup.close();
			if($(InsuranceChk).is('#travel-insurance-chk-yes')){
				st = "ON";	
			}
			else{
				st = "OFF";	
			}
			//add_insurance_fee
			cfy.additionalFee(st);
			
		}
		else{
			//console.log(cfy.quoteData);
			if($(InsuranceChk).is(':checked')){
				//console.log($("#tinsurance").data("fee"));
				cfy.quote.trip_insurance = "ON";	
			}
			else{
				cfy.quote.trip_insurance ='OFF';	
			}
			cfy.quote.get();	
		}
	},
	
	impactFee: function(impactfees){
		if($(impactfees).is('#lge-yes')){
			imf = "ON";	
		}
		else{
			imf = "OFF";	
		}
		cfy.openModal();
		$.post(cfy.xhrURL+"booking/quote/add_impact_fee",
			{	
				impact_fee:imf		
				
			},
			function(response){
				cfy.closeModal();
				total_amount = cfy.USCurrency(response.total_price);				
				
				$("#tiamount").html('$'+ Number(response.result.trip_insurance).toFixed(2));
				if(response.result.trip_insurance_status == 'ON')		{
					$("#tifee2").html('$'+ Number(response.result.trip_insurance).toFixed(2));
					
				}
				else{
					$("#tifee2").html('$0.00');
				}
				
				$("#impactfee").html('$'+ Number(response.result.Impact_Fee).toFixed(2));
				$("#tax-amount").html('$'+ Number(response.result.tax).toFixed(2));
				$("#total-amount").html('<b>$'+ total_amount+'</b>');
				$('.full_deposit').val(Number(parseFloat(response.total_price)).toFixed(2));
				$('.full_deposit_lbl').html(total_amount);
				if($(".min_deposit_lbl").length > 0)
				{
					minDiposit = Number(response.min_deposit).toFixed(2);
					$(".min_deposit_lbl").html(minDiposit);
					$(".min_deposit_val").val(minDiposit);
				}
			},			
		'json');		
		
	},
	showAddonBox:function(){
		$(".addon-box").show();
		$("#book-now-btn").addClass('book-now-visible');
		
	},
	hideAddonBox:function(){
		$(".addon-box").hide();
		$("#book-now-btn").removeClass('book-now-visible');
		
	},
	
	getBlockedDate:function(){
		
		$.get(cfy.xhrURL+"booking/quote/get_blocked_date",
			{					
				cb_unit_id: $("#cb-unit-id").val(),				
				
			},
			function(response){
				
				//cfy.quote.quoteData = response;
				availability_and_rates = response;
				minNightReq = response;
			},			
		'json')
		.fail( function(xhr, textStatus, errorThrown) {
			
		});
	},
	
	parseDate: function(str){
		mdy = str.split('-');
		return new Date(mdy[2], mdy[0]-1, mdy[1]);
		
	},
	daydiff:function(first, second ){
		if(first.toDateString() == second.toDateString()) { return '1'; }
		return Math.round((second-first)/(1000*60*60*24));
		
	},
	datePicker:function(){
		/* if (typeof isBlueGreen !== 'undefined' ){ 
			cfy.quote.mn_nights =2;	
			
		}
		else if(minNightReq.length> 0){
			cfy.quote.mn_nights = (parseInt(minNightReq[0].mn_nights) + 1);	
		}
		else { */
			//default Min Night setting
			cfy.quote.mn_nights =3;	
		//}
		var dtobj =  new Date();
		var unitId = $("#cb-unit-id").val();
		var maxBookingDays = 15;
		if(unitId == 521823 || unitId == 448121)
		{
			maxBookingDays= 0;
		}
		var rr = 	$('#search-date-range-wrap-quote').dateRangePicker({
			startDate: new Date(),
			selectForward: true,
			stickyMonths: true,
			maxDays: maxBookingDays,
			singleMonth: 'true',
			minDays: cfy.quote.mn_nights,
			hoveringTooltip: function(days, startTime, hoveringTime)
			{
				return days > 1 ? (days-1) + ' ' + 'Nights' : '';
			},
			autoClose: true,			
			customTopBar: '<div class="row calendar-header"><div class="small-12 columns">Please choose <span class="you">your</span> dates for<br />Check-in <strong>AND THEN</strong> Check-out.</div><div class="small-12 columns"><button id="date-range-clear-quote">Clear Dates</button></div></div>',
			format: 'MM-DD-YYYY',
			separator: ' → ',
			endDate: dtobj.setMonth(dtobj.getMonth() + 15),
			getValue: function()
			{
				/* if ($('#check-in').val() && $('#check-out').val() )
					return $('#check-in').val() + ' to ' + $('#check-out').val();
					else
				return ''; */
				return this.innerHTML;
			},
			setValue: function(s,s1,s2)
			{					
				//$('#check-in').val(s1);
				//$('#check-out').val(s2);
				cfy.quote.arrival = s1;
				cfy.quote.departure = s2;				
				if(s1 && s2){
					cfy.quote.nights =	cfy.daydiff(cfy.parseDate(s1),cfy.parseDate(s2));		
				}
				this.innerHTML = s;
			},
			showDateFilter: function(time, date)
			{
				var htmldate  = date;
				var dateString = moment(time).format("YYYYMMDD"); 
				var DailyRate ='';
				for(k=0;k<availability_and_rates.length;k++){
					
					if(dateString == availability_and_rates[k].calendar_date && availability_and_rates[k]['booked'] == 0){	
						DailyRate = parseFloat( availability_and_rates[k].daily_rate);
						STD_DailyRate = parseFloat( availability_and_rates[k].STD_daily_rate);
						if(parseFloat(DailyRate) < parseFloat(STD_DailyRate))
						{						
							htmldate  = 	'<div class="day-price-box"><span>'+date+'</span></div><div class="day-rate"><span class="daily-rate-cross">$' + STD_DailyRate.toFixed(0)+'</span><br><span class="daily-rate-now">$'+DailyRate.toFixed(0)+'</span></div>';
						}
						else
						{
							htmldate  = 	'<div class="day-price-box"><span>'+date+'</span></div><div class="day-rate">$' + DailyRate.toFixed(0)+'</div>';
						}
						break;
					}	
					else{
						htmldate=date;
					}
				}	
				return htmldate;
			},
			beforeShowDay: function(t)
			{
				var _valid = true;  //disable saturday and sunday
				var _class = '';
				var _tooltip = '';			
				
				var yyyy = t.getFullYear();
				var dd = (t.getDate() < 10 ? '0' : '') + t.getDate();
				
				var mm = ((t.getMonth() + 1) < 10 ? '0' : '') + (t.getMonth() + 1);
				
				var shortDate = yyyy+mm+dd;
				//console.log(dd);
				
				for (var k = 0; k < availability_and_rates.length; k++) 
				{	
					if (shortDate == availability_and_rates[k].calendar_date) 
					{	//condition for checking booked date
						if(availability_and_rates[k]['booked'] == 1)
						{	//adding departure-ok class and make it valid date
							_valid = (availability_and_rates[k]['departure_okay'] == 1 ) ? true : false;  
							_class =  (availability_and_rates[k]['departure_okay'] == 1 ) ?  'departure-ok' : 'disabled';
							_tooltip = (availability_and_rates[k]['departure_okay'] == 1 ) ?  'Checkout Only' : '';										
							break;										
						}
						else
						{	//valid date and arrival-okay class
							_valid =  true ;  
							_class =  (availability_and_rates[k]['arrival_okay'] == 1 ) ?  'arrival-ok' : 'allow';
							_tooltip = (availability_and_rates[k]['arrival_okay'] == 1 ) ?  'Checkin Only' : '';										
							break;										
						}
					}
				}					
				
				return [_valid,_class,_tooltip];
			}
		}).bind('datepicker-opened',function()
		{
			
		}).bind('datepicker-first-date-selected', function(event, obj)
		{
			/* This event will be triggered when first date is selected */		
			/* var first_date = obj.date1;
			var first_date_year = first_date.getFullYear();
			var first_date_month =  ((first_date.getMonth() + 1) < 10 ? '0' : '') + (first_date.getMonth() + 1);
			var first_date_day =   (first_date.getDate() < 10 ? '0' : '') + first_date.getDate(); 
			var first_date1 = first_date_year + ""  + first_date_month + "" +first_date_day; */
			//var first_date1 = moment(f_date_format);
			//var first_date = parseInt(first_date_month+first_date_day+first_date_year);
			cfy.quote.departureSelected = false;
			
			
			/* if(minNightReq.length > 0) {
				for (var i=0; i<minNightReq.length; i++){
					var begin_date = minNightReq[i].calendar_date;
								
					var mn_nights_stay = '';
					if(first_date1 == begin_date &&  parseInt(minNightReq[i].mn_nights) >= 2 ){
						
						
						mn_nights_stay = (parseInt(minNightReq[i].mn_nights) + 1);
						var allow_minNight = true;
						
						//Loop to check Min Nights requirments
						for(var m=0; m < minNightReq[i].mn_nights; m++)
						{
							//available dates
							var fadte = moment(begin_date).add(m, 'days');
							//date format for matching with calendar date
							var fadte_format =  moment(fadte).format("YYYYMMDD");
							console.log(fadte_format);
							console.log(minNightReq[i+m].calendar_date);
							//condition for check min nights continues date available 
							if(fadte_format == minNightReq[i+m].calendar_date)
							{	
								// continuous calendar min nights available
								allow_minNight = true;
							}
							else
							{	// continuous calendar min nights not available
								allow_minNight = false;
							}
							
						}
						
						//condition if min nights date available then display minNights calendar footer text and setMinDays dynamically
						if(allow_minNight)
						{
							$('#search-date-range-wrap-quote').data('dateRangePicker').setMinDays(mn_nights_stay);
							$('.date-picker-wrapper').find('.footer').html('<span class="min-stay-datepicker" >There is a minimum stay requirement of <br />'+minNightReq[i].mn_nights+' nights' + '</span>');
						}
						else
						{
							//else min nights date not available then setMinDays default 2 nights dynamically (3 means two nights)
							$('#search-date-range-wrap-quote').data('dateRangePicker').setMinDays(3);
						}
						break;
					}
					else {
						
						$('#search-date-range-wrap-quote').data('dateRangePicker').setMinDays(cfy.quote.mn_nights);						
						$('.date-picker-wrapper').find('.footer').html('');
					}
				}
				
			} */
			
			
		}).bind('datepicker-close',function()
		{
			if (cfy.quote.departureSelected  === false && typeof  cfy.quote.quoteData.TotalPrice === 'undefined')
			{
				$('#search-date-range-wrap-quote').data('dateRangePicker').clear();
				$('#search-date-range-wrap-quote').html('Choose <span class="you">Your</span> dates');
			}
			
		}).bind('datepicker-change',function(event,obj)
		{			
			cfy.quote.departureSelected = true;			
			//quote generate on date select
			cfy.quote.get();
			
		});		
		$('#date-range-clear-quote').click(function(evt) {
			evt.stopPropagation();
			$('#search-date-range-wrap-quote').data('dateRangePicker').clear();
			$('#search-date-range-wrap-quote').data('dateRangePicker').setAutoClose(true);
			//$('#search-date-range-wrap-quote').data('dateRangePicker').close();
			$('#search-date-range-wrap-quote').data('dateRangePicker').open();
			$('#search-date-range-wrap-quote').html('Choose <span class="you">Your</span> dates');
			$('.date-picker-wrapper').find('.footer').html('');
			//clear quotes placeholder on click on clear button
			$("#quote-box").html('');
			cfy.hideAddonBox();
			cfy.setCheckinCheckoutDate();
			$('#quote-sticky').foundation();
			$('#quote-sticky').foundation('_calc', true);
			cfy.quote.chtsticky=1;
		});			
		
	},
	rates_and_availability:function(){
		$( "#rates-and-availability" ).datepicker({
			minDate: 0,
			format: 'MM-DD-YYYY',
			numberOfMonths: [ 2, 2 ],
			onChangeMonthYear: function(month, year, dp) {
				cfy.updateDatePickerCells();
			},
			onSelect: function(date, inst){
				inst.inline = false;
				
				//cfy.updateDatePickerCells();
			},
			
			beforeShowDay: function(t) {
				var valid = true;  //disable saturday and sunday
				var _class = '';
				var _tooltip = '';			
				
				var yyyy = t.getFullYear();
				var dd = (t.getDate() < 10 ? '0' : '') + t.getDate();
				
				var mm = ((t.getMonth() + 1) < 10 ? '0' : '') + (t.getMonth() + 1);
				
				var shortDate = yyyy+mm+dd;
				//console.log(dd);
				for(k=0;k<availability_and_rates.length;k++){		
					//console.log(shortDate);
					if(shortDate == availability_and_rates[k].calendar_date){					
						// valid = false;  
						_class = 'available';
						_tooltip =  '$' + availability_and_rates[k].daily_rate;
						break;
					}	
					else{
						_class = 'disabled';
						_tooltip =  '';
					}
				}					
				
				return [valid,_class,_tooltip];
			}		
			
		});	
	},
	guest:{
		first_name:'',
		last_name:'',
		address:'',
		email:'',
		city:'',
		state:'',
		zipcode:'',
		phone_number:'',
		alternate_phone_number:'',
		terms:'',	
		submit:function(){	
			
			grecaptcha.ready(function() {
				grecaptcha.execute(v3_recaptcha_site_key, {action: 'bookingForm'}).then(function(token) {
					// Add your logic to submit to your backend server here.
					$('.g-recaptcha-response').val(token);
					
					$('#guest_info_form').ajaxForm({
						dataType: 'json',
						beforeSubmit: function(e) {
							$('.ajaxloader').html('<img src="'+cfy.xhrURL+'/public/img/ajax-loader.gif">');
						},
						success: function(data) {               
							
							if (data.status == 'success') {   
								$.notify("Please wait...", {position:'t c',className: 'success'});		
								window.location.href= data.redirect;
								//	window.location.href= data.redirect_url;
							}
							else{
								$('.ajaxloader').html('');
								$.notify(data.message, {position:'t c',className: 'error'});
								$.each(data.errorFields, function(key, value) {
									$('#' + key).addClass('has-error');
								});
							}
						},
						error: function(e) {}
					}).submit();
					
				});
			});
			
		}
	},
	leads:function(){	
		$('#newsletter-slide-out').ajaxForm({
			dataType: 'json',
			beforeSubmit: function(e) {
				$('.sajaxloader').html('<img src="'+cfy.xhrURL+'/public/img/ajax-loader.gif">');
			},
			success: function(data) {               
				
				if (data.status == 'success') {   
					$('.sajaxloader').html("<span style='color:#2c6c25;font-weight:bold;'>Thank you for your submission.</span>");
					$('#newsletter-slide-out')[0].reset();
					
				}
				else{
					$('.sajaxloader').html('');
					$('.sajaxloader').html("<span style='color:#dc483b;font-weight:bold;'>"+data.message+"</span>") ;
				}
			},
			error: function(e) {}
		}).submit();	
		
	},
	
	leadsPopup:function(){	
		$('#intent-to-exit').ajaxForm({
			dataType: 'json',
			beforeSubmit: function(e) {
				$('.iajaxloader').html('<img src="'+cfy.xhrURL+'/public/img/ajax-loader.gif">');
			},
			success: function(data) {               
				
				if (data.status == 'success') {   
					$('.iajaxloader').html("<div style='color:#2c6c25;font-weight:bold;text-align: center;'><p>Thank you for<br />your submission.</p></div>");
					$('#intent-to-exit')[0].reset();
					
				}
				else{
					$('.sajaxloader').html('');
					$('.iajaxloader').html("<div style='color:#dc483b;font-weight:bold;text-align: center;'>"+data.message+"</div>") ;
					$('.iajaxloader p').css('padding-top','0px');
				}
			},
			error: function(e) {}
		}).submit();	
		
	},
	barry_checkout:function(){
		
		$('#barry_checkout_form').ajaxForm({
			dataType: 'json',
			beforeSubmit: function(e) {
				$('.ajaxloader').html('<img src="'+cfy.xhrURL+'/public/img/ajax-loader.gif">');
			},
			success: function(data) {               
				
				if (data.status == 'success') {   
					$.notify("Please wait...", {position:'t c',className: 'success'});		
					window.location.href= data.redirect;
					//	window.location.href= data.redirect_url;
				}
				else{
					$('.ajaxloader').html('');
					$.notify(data.message, { autoHide: false, position:'t c',className: 'error'});
					$.each(data.errorFields, function(key, value) {
						$('#' + key).addClass('has-error');
					});
				}
			},
			error: function(e) {}
		}).submit();
		
		
		
	},
	
	cart:{
		package_id:'',
		quantity:'',
		add:function(){
			//$("#quote-box").html('<img src="'+cfy.xhrURL+'public/img/ajax-loader.gif" >');
			$.get(cfy.xhrURL+"booking/cart/add",
				{	
					package_id:cfy.cart.package_id,
					quantity: cfy.cart.quantity
					
				},
				function(response){				
					if(response.status == "success")
					{
						var html_packages = "";
						for (var key in response.packageData) {
							if (response.packageData.hasOwnProperty(key)) {  
								html_packages += "<div class='medium-7 small-7 columns cart-item-"+key+"' >"+response.packageData[key].package_name.substring(0, 19)+": ("+response.packageData[key].quantity+")</div><div class='medium-5 small-5 columns text-right cart-item-"+key+"'><a data-pid='"+key+"' class='remove-cart-package'>Remove</a>"+ " $" +response.packageData[key].package_total.toFixed(2)+"   </div>";
								
							}
						}
						$('.package-items').html(html_packages);
						total_amount = parseFloat(response.total_price);
						$('#total-amount').html('<b>$'+Number(total_amount).toFixed(2)+'</b>');
						$('#tax-amount').html(' $'+ Number(response.total_tax).toFixed(2) );
						if($("#travel-insurance-chk").is(":checked")){	$("#tifee2").html('$'+ response.total_trip_insurance); }
						$.notify(response.message, {position:'t c',className: 'success'});
						
						
					}
					else{					
						
						$.notify(response.message, {position:'t c',className: 'error'});
					}
				},			
			'json');			
		}	,
		remove:function(obj){
			$(".cart-list").append('<p class="loader text-center"><img src="'+cfy.xhrURL+'public/img/ajax-loader.gif" ></p>');
			$.get(cfy.xhrURL+'booking/cart/remove/'+$(obj).data('pid'),	{			
				
			},
			function(response){					
				$(".cart-item-"+ $(obj).data('pid')).remove();
				//$(".package-total-box").html(response.total_price);
				total_amount = response.total_price;
				$('#total-amount').html('<b>$'+Number(total_amount).toFixed(2)+'</b>');
				$('#tax-amount').html(' $'+response.total_tax);
				if($("#travel-insurance-chk").is(":checked")){	$("#tifee2").html('$'+ response.total_trip_insurance); }
				$(".loader").remove(); 
			},			
			'json');
			
		}
	},
	updateDatePickerCells:function(){	
		setTimeout(function () {
			$('table.ui-datepicker-calendar tbody tr td').each(function(){
				var calendarPrice = $(this).attr('title');
				if(calendarPrice !== undefined){					
					$(this).find('a').append('<span style="padding:0 5px;" class="calendar-price">' + calendarPrice + '</span>');
				}
			});   
			$('td.disabled').next('td.available').addClass('arrival-ok');
			$('tr td.available').next('td.disabled').addClass('departure-ok');
			
			$('tr td.disabled:last-child').parent().next().children('td.available:first-child').addClass('arrival-ok');
			
			$('td.arrival-ok').attr('title',' Arrival Okay');
			$('td.departure-ok').attr('title',' Departure Okay');
			
			/* 
				$('.ui-datepicker td > *').each(function (idx, elem) {
				var value = '$125';//cellContents[idx + 1] || 0;
				var className = 'datepicker-content-' + CryptoJS.MD5(value).toString();
				if(value == 0)
				addCSSRule('.ui-datepicker td a.' + className + ':after {content: "\\a0";}'); //&nbsp;
				else
				addCSSRule('.ui-datepicker td a.' + className + ':after {content: "' + value + '";}');
				$(this).addClass(className);
			}); */
		}, 100);
		
	},
	TripInsurancePopup: function(){
		
		if($('.addon-box-review').length > 0 &&	$("input[name='ppfee']:checked").val() != '0.00' && cfy.tippyOpen!=3){	
			//if open already close it
			if(cfy.tippyOpen == 2){
				cfy.TripInsurancePopupClose();
			}
			cfy.tippyOpen = 2;
			cfy.trippopup = $("#tripInsuranceModal");	
			//reveal popup open
			$(cfy.trippopup).foundation('open');
		}
		else {	
			window.location = cfy.xhrURL+"booking/review_booking";		
		}	
	},
	
	TripInsurancePopupClose:function(){
		//reveal popup close
		$(cfy.trippopup).foundation('close');	
	},
	tripCall:function(){
		if($("input[name='tripInsuranceRadio']:checked").val() =="yes"){
			$('#travel-insurance-chk').prop('checked', true);		
			ty = $('#travel-insurance-chk');
			cfy.tripInsuranceFee(ty);	
		}
		else {
			$('#travel-insurance-chk').prop('checked', false);	
			ty = $('#travel-insurance-chk');
			cfy.tripInsuranceFee(ty);
			
		}
		$(".triploader").html('<img src="'+cfy.xhrURL+'public/img/ajax-loader.gif" >');
		$.get(cfy.xhrURL+"booking/quote/track_tripinsurance",
			{
				chkbtn:$("input[name='tripInsuranceRadio']:checked").val()								
			},
			function(response){				
				if(response.status == "success")
				{					
					//window.location = cfy.xhrURL+"booking/review_booking";
					
					cfy.guest.submit();
					cfy.TripInsurancePopupClose();
				}
				else{					
					window.location = cfy.xhrURL+"booking/review_booking";
				}
			},			
		'json');
		
		//$('.continue-review-page').attr('href',cfy.xhrURL+"booking/review_booking");
		cfy.tippyOpen = 3;
	},
	
	setCheckinCheckoutDate: function(d1,d2){
		
		$.get(cfy.xhrURL+"booking/quote/set_checkin_checkout_date",
			{
				check_in:d1,
				check_out:d2
			},
			function(response){			
				
			},			
		'json');
		
		
	}
	
};	

$(document).on('closed.zf.reveal', '#rewards-modal', function () {
	sessionStorage.setItem('rewardspopState','shown');
});


$(document).ready(function() {

	var propertyID = $("#cb-unit-id").val();
	
	if($("#rewards-modal").length && sessionStorage.getItem('rewardspopState') != 'shown' && propertyID != '811611')
	{
		setTimeout(function(){ $("#rewards-modal").foundation('open'); }, 10000);
	}
	$('.minimum_deposit').on('click',function(e){
		var min_deposit_amnt   = $(this).val();
		$("#payment-form").attr('src',cfy.xhrURL+'public/img/ajax-loader.gif');
		$.get(cfy.xhrURL+"booking/checkout/min_deposit",
			{
				min_deposit_amount:min_deposit_amnt								
			},
			function(response){	
				if(response.status == 'success'){
					$("#payment-form").attr('src',response.redirect_url);
				}
				else {
					$("#payment-form").attr('src',cfy.xhrURL+'public/img/error.jpg');
				}
			},			
		'json');
	});
	
	
	$('#online-payment-button').on('click',function(e){
		cfy.GetResInfo();	
	});
	
	$(document).on("click",'#p-submit-button',function(e){	
		cfy.payment();	
	});
	
	$(document).on("click",'.balance_due_other',function(e){
		$('#payment_form_step3').hide();	
	}); 
	
	
	$(document).on("click",'.balance_due, #update_due_amount',function(e){
		$('#payment_form_step3').show();	
	});
	
	
	
	
	//newsletter-slide-out
	$('.newsletter-button').on('click',function(e){
		cfy.leads();	
	});
	$('.intent-to-exit-btn').on('click',function(e){
		cfy.leadsPopup();	
	});
	
	//Auto Quote generate on page load
	if($('#search-date-range-wrap-quote').length > 0){
		var sa1 =  $('#check-in').val();
		var 	sa2 = $('#check-out').val();
		cfy.quote.arrival = sa1;
		cfy.quote.departure = sa2;
		cfy.quote.nights =	cfy.daydiff(cfy.parseDate(sa1),cfy.parseDate(sa2));		
		if( sa1!=='' && sa2!=''){
			cfy.quote.get();
		}
	}
	
	
	
	$('.fa-close').on('click',function(e){
		$('.sajaxloader').html('');
	});
	
	// This will create a single gallery from all elements that have class "gallery-item"
	$('.gallery').each(function() { // the containers for all your galleries
		$(this).magnificPopup({
			delegate: 'a', // the selector for gallery item
			type: 'image',
			gallery: {
				enabled: true,
				navigateByImgClick: true,
				preload: [0,1] // Will preload 0 - before current, and 1 after the current image
			},
			callbacks: {
				resize: changeImgSize,
				imageLoadComplete:changeImgSize,
				change:changeImgSize
			}
		});
	});
	
	function changeImgSize(){
		var img = this.content.find('img');
		img.css('max-height', '100%');
		img.css('width', 'auto');
		img.css('max-width', 'auto');
	}
	
	//Floorplan popup
	
	$('#floorplans').magnificPopup({
		delegate: 'a', // the selector for gallery item
		type: 'image',
		gallery: {
			enabled: true,
			navigateByImgClick: true,
			preload: [0,1] // Will preload 0 - before current, and 1 after the current image
		},
	});
	
	
	// $("#book-now-btn").hide();
	//$('#check-in').focus();
	$("#coupon-code-apply-btn").on("click", function(){
		var Pcode = $("#coupon-code-field").val();
		if(Pcode !==''){
			cfy.couponDiscount();
		}
		else{
			$("#coupon-code-field").notify("Please enter discount code", {position:'t c',className: 'error'});
		}	
	});
	
	$("#coupon-code-form").hide();
	$("#apply-discount-btn").on("click", function(e){
		e.preventDefault() ;
		$("#coupon-code-form").slideToggle();	
		
	});
	
	
	//add to cart package
	$(".add-to-cart").on("click",function(){
		cfy.cart.package_id = $(this).data('package');
		cfy.cart.quantity = $('#pkg_quantity_'+cfy.cart.package_id).val();
		cfy.cart.add();	
	});
	
	//View cart packages
	$('.cart-view-popup').magnificPopup({
		preloader: true,
		type: 'ajax'
	});
	
	//delete cart packages
	$(document).on("click",'.remove-cart-package',function(e){
		e.preventDefault();
		cfy.cart.remove(this);
	});
	
	//Barry checkout form action
	$("#barry-checkout-paypal-payment").on("click",function(){
		
		cfy.barry_checkout();
		
	});
	
	$("#rental-submit-button").on("click",function(){
		if($("#rental-submit-button").hasClass('ota-cabin')){
			cfy.guest.submit();
		}
		else 
		{
			if($("input[name='lge']").length > 0)
			{				
				if($("input[name='cfy_rewards']").is(":checked") && $("input[name='lge']").is(":checked")){
					
					if( $("#cfy-rewards-chk-yes").is(":checked") && !$("#mtc").is(":checked"))
					{
						$.notify("Please accept membership terms & conditions", {position:'t c',className: 'error'});
						return false;
					}
					
					
					cfy.guest.submit();
				}
				else if(! $("input[name='cfy_rewards']").is(":checked") )
				{
					$.notify("Please make a selection for rewards member", {position:'t c',className: 'error'});
					$(".trip-field").addClass('has-error');
					
				}
				else 
				{
					$.notify("Please make a selection for Large Group Events", {position:'t c',className: 'error'});
					$(".trip-field-lg").addClass('has-error');
				}
			}			
			else if($("input[name='cfy_rewards']").length > 0)
			{			
				
				if($("input[name='cfy_rewards']").is(":checked") )
				{
					if( $("#cfy-rewards-chk-yes").is(":checked") && !$("#mtc").is(":checked"))
					{
						$.notify("Please accept membership terms & conditions", {position:'t c',className: 'error'});
						return false;
					}
					cfy.guest.submit();
				}
				else
				{
					$.notify("Please make a selection for rewards member", {position:'t c',className: 'error'});
					$(".trip-field").addClass('has-error');		
				}
				
				
			}
			else {
				
				cfy.guest.submit();				
			}
			
		}		
		
		
	});
	
	$("#guest_info_form input, #guest_info_form select, .trip-field, .trip-field-lg, #tc").on('click', function(e) {
		$(this).removeClass('has-error');
		
	});
	
	$(document).on('click', '#pform input, #pform select', function(e){
		
		$(this).removeClass('has-error');
		
	});
	
	/* if($("#cb-unit-id").length > 0)
		{
		cfy.getBlockedDate();
	} */
	
	if($('#search-date-range-wrap-quote').length > 0){
		cfy.datePicker();
	}
	cfy.hideAddonBox();
	if($('#rates-and-availability').length > 0){
		cfy.rates_and_availability();
	}
	
	if($('.addon-box-review').length > 0 &&	$("input[name='ppfee']:checked").val() != '0.00'){
		//	$("#property-protection-chk").attr("checked",true);
	}
	else if($('.addon-box').length > 0 ){
		$("#property-protection-chk").attr("checked",true);
		//$("#cancellation-protection-chk").attr("checked",true);
	}
	
	
	/* 	$("#get-quote-button").on("click", function(){	
		if(parseInt(cfy.quote.nights) > 14){
		$("#quote-box").html('<div class="error">Only 14 nights may be booked in a single reservation</div>');
		}
		else {
		if($("#pets").length > 0){
		cfy.quote.pets = $("#pets").val(); 				
		}
		if($("#numberofadults").length > 0){
		cfy.quote.adults = $("#numberofadults").val();
		}
		cfy.quote.get();	
		}
		});
	*/
	
	$("#pets, #numberofadults").on("change", function(){
		if(parseInt(cfy.quote.nights) > 14){
			$("#quote-box").html('<div class="error">Only 14 nights may be booked in a single reservation</div>');
		}	
		else {
			cfy.quote.get();	
		}
	});
	
	
	$("#travel-insurance-chk").on("click", function(){
		cfy.tripInsuranceFee(this);
	});
	$(".travel-insurance-chk-rev").on("click", function(){
		cfy.tripInsuranceFee(this);
	});
	
	//review page 
	$(".cfy-rewards-chk-rev").on("click", function(){
		cfy.rewardsMember(this);
	});
	
	$(".lge").on("click", function(){
		cfy.impactFee(this);
	});
	
	
	$("#property-protection-chk").on("click", function(){
		cfy.quote.damage_insurance_orig = $(this).data('fee');
		cfy.propertyProtectionFee(this);
	});
	
	//Popup Why buy Trip Insurance?
	$('#travel-insurance-btn').magnificPopup({
		items: {
			src: cfy.xhrURL+'pdf/redsky-because.pdf'
		},
		type: 'iframe'
	});
	
	//Popup CFY Member Rewards details page
	$('#cfy-rewards-btn').magnificPopup({
		items: {
			src: cfy.xhrURL+'pdf/cfy-rewards.pdf'
		},
		type: 'iframe'
	});
	
	//Barry popup
	$('.barry-tc-popup').magnificPopup({
		items: {
			src: cfy.xhrURL+'pdf/cfy-product-terms-conditions.pdf'
		},
		type: 'iframe'
	});
	
	
	
	$("#cfy-rewards-chk").on('change click',function(){		
		$.magnificPopup.open({
			items: {
				src: '#rewards-member-popup-box',
				type: 'inline'
			},
			callbacks: {
				elementParse: function(item) {
					$("#rewards-member-popup-box").show();					
					
				}
			}
		});
		
	});
	
	//Popup PDF Cancellation Protection
	$('#cancellation-protection-btn').magnificPopup({
		items: {
			src: cfy.xhrURL+'pdf/Cancellation-Protection.pdf'
		},
		type: 'iframe'
	});
	
	
	$("#cancellation-protection-chk").on('change click',function(){
		
		$.magnificPopup.open({
			items: {
				src: '#cancellation-protection-popup-box',
				type: 'inline'
			},
			callbacks: {
				elementParse: function(item) {
					$("#cancellation-protection-popup-box").show();
					//$("#ppfee1").prop('checked', true);
					
				}
			}
		});
		
	});
	
	
	$(".CancellationProtectionExtraOption").on("click", function(){
		
		$(".CancellationProtectionExtraOption").parent().find('ul').hide();
		$(this).parent().find('ul').show();	
		cfy.quote.cp_amount = $(this).val();
		
	});
	
	
	
	$("#join_club_btn").on("click", function(){
		
		//cfy.calculateTotalPrice(cfy.quote.quoteData);
		
		if($("#cfy-rewards-chk-y").is(":checked"))
		{
			$("#cfy-rewards-chk").prop( "checked" ,true);
			cfy.quote.membership = 1;
			
		}
		else {
			$("#cfy-rewards-chk").prop( "checked" ,false);
			cfy.quote.membership = 0;
		}	
		cfy.quote.get();
		
		$.magnificPopup.close();
		
		
	});
	
	$("#cfy-rewards-chk-n, #cfy-rewards-chk-no").on("click", function(){
		$("#mtc").prop('checked', false);
		$("#mtcError").html("");
	});
	
	$("#cptbtn").on("click", function(){
		//for review page
		if($(this).hasClass('review-page-cancellation-btn')){
			cfy.quote.cp_amount = $('input[name=cpfee]:checked').val();
			console.log($('input[name=cpfee]:checked').val());
			cfy.add_cancellation_fee();	
		}
		else{
			//cfy.calculateTotalPrice(cfy.quote.quoteData);
			cfy.quote.cp_amount = $('input[name=cpfee]:checked').val();
			if(parseFloat(cfy.quote.cp_amount) > parseFloat(0.00)){
				$("#cancellation-protection-chk").prop( "checked" ,true);
			}
			else {
				$("#cancellation-protection-chk").prop( "checked" ,false);
			}	
			cfy.quote.get();
		}
		$.magnificPopup.close();
		
		
	});
	
	
	//Cancellation-Protection Popup end
	
	//Popup Video Why buy Property Protection?
	$('.popup-youtube').magnificPopup({
		iframe: {
			markup: '<div class="mfp-iframe-scaler">'+
			'<div class="mfp-close"></div>'+
			'<iframe class="mfp-iframe" frameborder="0" allowfullscreen></iframe>'+
			'</div>', // HTML markup of popup, `mfp-close` will be replaced by the close button
			
			patterns: {
				youtube: {
					index: 'youtube.com/', // String that detects type of video (in this case YouTube). Simply via url.indexOf(index).
					
					id: 'v=', // String that splits URL in a two parts, second part should be %id%
					// Or null - full URL will be returned
					// Or a function that should return %id%, for example:
					// id: function(url) { return 'parsed id'; }
					
					src: '//www.youtube.com/embed/-mRIha0DrI0?autoplay=1&rel=0' // URL that will be set as a source for iframe.
				}
				
			},
			
			srcAction: 'iframe_src', // Templating object key. First part defines CSS selector, second attribute. "iframe_src" means: find "iframe" and set attribute "src".
		}
	});
	
	
	$(".damageInsuranceExtraOption").on("click", function(){
		
		$(".damageInsuranceExtraOption").parent().find('ul').hide();
		$(this).parent().find('ul').show();
		cfy.quote.quoteData.damage_insurance = $(this).val();
		cfy.quote.pp_amount = $(this).val();
		
	});
	
	$(".cfy-rewards-radio-chk").on("click", function(){		
		
		cfy.quote.membership = $(this).val();
		
	});
	
	
	$("#acptbtn").on("click", function(){
		//for review page
		if($(this).hasClass('review-page-btn')){
			//cfy.quote.damage_insurance_orig = $('input[name=ppfee]:checked').val();
			cfy.quote.pp_amount = $('input[name=ppfee]:checked').val();
			cfy.additional_Property_Fee();	
			/* if(cfy.quote.damage_insurance_orig != '0.00'){
				cfy.TripInsurancePopup();
			} */
		}
		else{
			//cfy.calculateTotalPrice(cfy.quote.quoteData);
			if(parseFloat(cfy.quote.pp_amount) > parseFloat(0.00)){
				$("#property-protection-chk").prop( "checked" ,true);
			}
			else {
				$("#property-protection-chk").prop( "checked" ,false);
			}	
			cfy.quote.get();
		}
		$.magnificPopup.close();
		
		
	});
	
	/* 	if($('.addon-box-review').length > 0 &&	$("input[name='ppfee']:checked").val() != '0.00'){
		cfy.TripInsurancePopup();
	} */
	
	
	$("#book-now-btn").on("click", function(){
		//$("#booknow-loader").html('<img src="'+cfy.xhrURL+'public/img/ajax-loader.gif" >');
		cfy.bookNow();		
	});
	
	$('.open-popup-link').magnificPopup({
		type:'inline',
		midClick: true // Allow opening popup on middle mouse click. Always set it to true if you don't provide alternative source in href.
	});
	// this part overrides "close" method in MagnificPopup object
	$.magnificPopup.instance.close = function () {
		
		if($("#cfy-rewards-chk").length > 0){
			
			if(cfy.quote.membership == 1){
				$("#cfy-rewards-chk").prop( "checked" ,true);
			}
			else {
				$("#cfy-rewards-chk").prop( "checked" ,false);
			}	
		}
		// "proto" variable holds MagnificPopup class prototype
		// The above change that we did to instance is not applied to the prototype, 
		// which allows us to call parent method:
		if($("#cancellation-protection-chk").length > 0){
			
			if(parseFloat(cfy.quote.cp_amount) > parseFloat(0.00)){
				$("#cancellation-protection-chk").prop( "checked" ,true);
			}
			else {
				$("#cancellation-protection-chk").prop( "checked" ,false);
			}	
		}
		
		if($("#property-protection-chk").length > 0){
			if(parseFloat(cfy.quote.pp_amount) > parseFloat(0.00)){
				$("#property-protection-chk").prop( "checked" ,true);
			}
			else {
				$("#property-protection-chk").prop( "checked" ,false);
			}	
		}
		
		
		$.magnificPopup.proto.close.call(this);
	};
	
	
	$(document).on("click", '#show-toggle-fee', function(){
		
		$("#toggle-fee").slideToggle();
	});
	
});	

$(document).on('hover mouseover', 'table.ui-datepicker-calendar tbody tr td', function(e){
	e.preventDefault();
	$(this).off( "click" );
	
});
$(document).on('click', "#property-protection-chk", function(e){
	$.magnificPopup.open({
		items: {
			src: '#property-protection-popup-box',
			type: 'inline'
		},
		callbacks: {
			elementParse: function(item) {
				$("#property-protection-popup-box").show();									
			}
		}
	});	
});

$(document).on('click', "#tp-review-page", function(e){
	$.magnificPopup.open({
		items: {
			src: '#travel-insurance-popup-box',
			type: 'inline'
		},
		callbacks: {
			elementParse: function(item) {
				$("#travel-insurance-popup-box").show();									
			}
		}
	});	
});



$(document).on('click', 'table.ui-datepicker-calendar tbody tr td a', function(e){
	e.preventDefault();	
});

//$(window).on('beforeunload', function(e) {
/* 	$(document).on('change', "#guest_email_address", function(e){
	if($('#guest_info_form').length > 0)
	{
	
	var guest_first_name=$("#guest_first_name").val();
	var guest_last_name=$("#guest_last_name").val();
	var guest_email_address =$("#guest_email_address").val();
	var beds=$("#beds").val();
	var baths=$("#baths").val();
	var sleeps=$("#sleeps").val();	
	var checkin=$("#checkin").val();
	var checkout =$("#checkout").val();
	var unit_name=$("#unit_name").val();
	var  cabin_id= $("#cabin_id").val();
	var guest_phone_number = $("#guest_phone_number").val();
	var unit_featured_img=$("#unit_featured_img").val();
	//c=$("#booking_url").val();
	
	$.ajax({
	url : cfy.xhrURL+ "/cart_abandonment/saveCart",
	type: "POST",
	data : {'firstName':guest_first_name,'lastName':guest_last_name,'email_address':guest_email_address,'beds':beds,'baths':baths,'sleeps':sleeps,'checkin':checkin,'checkout':checkout,'unit_name':unit_name,'unit_featured_img':unit_featured_img,'cabin_id':cabin_id,'phoneNumber':guest_phone_number} ,
	success: function(data, textStatus, jqXHR)
	{	
	},
	error: function (jqXHR, textStatus, errorThrown)
	{
	
	}
	});
	//return 'save';
	}
	
    
}); */


//month1 span.next


function PopupCenter(url, title, w, h) {
	// Fixes dual-screen position                         Most browsers      Firefox
	var dualScreenLeft = window.screenLeft != undefined ? window.screenLeft : screen.left;
	var dualScreenTop = window.screenTop != undefined ? window.screenTop : screen.top;
	
	width = window.innerWidth ? window.innerWidth : document.documentElement.clientWidth ? document.documentElement.clientWidth : screen.width;
	height = window.innerHeight ? window.innerHeight : document.documentElement.clientHeight ? document.documentElement.clientHeight : screen.height;
	
	var left = ((width / 2) - (w / 2)) + dualScreenLeft;
	var top = ((height / 2) - (h / 2)) + dualScreenTop;
	var newWindow = window.open(url, title, 'scrollbars=yes, width=' + w + ', height=' + h + ', top=' + top + ', left=' + left);
	
	// Puts focus on the newWindow
	if (window.focus) {
		newWindow.focus();
	}
}


